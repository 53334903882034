import React, { useState } from "react";
import emailjs from "emailjs-com";
import Swal from "sweetalert2";
import "../Contactus/Connect.css";
import { useNavigate } from 'react-router-dom';

const Connect = () => {
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    mobile: "",
    option:"",
    query: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validate = (name, value) => {
    let error = "";

    switch (name) {
      case "name":
        if (!/^[a-zA-Z\s]+$/.test(value)) {
          error = "Name should only contain alphabets";
        }
        break;
      case "email":
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
          error = "Invalid email address";
        }
        break;
      case "mobile":
        if (!/^\d{10}$/.test(value)) {
          error = "Mobile should be a 10-digit number";
        }
        break;
        case "option":
        if (!value) error = "Please select an option";
        break;
      case "query":
        if (value.length < 3) {
          error = "Requirements should be at least 15 characters";
        }
        break;
      default:
        break;
    }

    return error;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });

    const error = validate(name, value);
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const errors = {};
    Object.keys(formValues).forEach((key) => {
      const error = validate(key, formValues[key]);
      if (error) errors[key] = error;
    });

    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      setIsSubmitting(true);
      emailjs
        .send('service_gwx9h8c', 'template_8fchos7', formValues, '5vvqi6coftIzkClRe')
        .then(
          (result) => {
            Swal.fire({
              title: 'Success!',
              text: 'Your message has been sent!',
              icon: 'success',
              confirmButtonText: 'OK'
            });
            setIsSubmitting(false);
            navigate('/thankyou');
          },
          (error) => {
            Swal.fire({
              title: 'Error!',
              text: 'Failed to send the message. Please try again.',
              icon: 'error',
              confirmButtonText: 'OK'
            });
            setIsSubmitting(false);
          }
        );
    } 
  };

  return (
    <section className="connect">
      <div className="left-con">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1808474.972448503!2d75.33016371181009!3d27.711799791863807!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ced2e59322f4d%3A0xaaac22d22d85eb13!2sAce%20Sector%2012%20Greater%20Noida%20West!5e0!3m2!1sen!2sin!4v1725439151937!5m2!1sen!2sin"
          width="100%"
          height="100%"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </div>
      <div className="right-con">
        <h2>Contact Us</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formValues.name}
              onChange={handleChange}
              required
            />
            {formErrors.name && <span className="error">{formErrors.name}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formValues.email}
              onChange={handleChange}
              required
            />
            {formErrors.email && <span className="error">{formErrors.email}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="mobile">Mobile</label>
            <input
              type="tel"
              id="mobile"
              name="mobile"
              value={formValues.mobile}
              onChange={handleChange}
              required
            />
            {formErrors.mobile && <span className="error">{formErrors.mobile}</span>}
          </div>
          <div className="form-group">
                        <label htmlFor="option">Select Flat Option:</label>
                        <select
                            id="option"
                            name="option"
                            value={formValues.option}
                            onChange={handleChange}
                            required
                            className={`input ${formErrors.option ? 'invalid' : ''}`}
                        >
                            <option value="">-Select-</option>
                            <option value="4.5-XL BHK(4190 SQ. FT.)">4.5-XL BHK(4190 SQ. FT.)</option>
                            <option value="4.5 BHK(3200 SQ. FT.)">4.5 BHK(3200 SQ. FT.)</option>
                            <option value="3.5 BHK(2290 SQ. FT.)">3.5 BHK(2290 SQ. FT.)</option>
                        </select>
                        {formErrors.option && <span className="error">{formErrors.option}</span>}
                    </div>
          <div className="form-group">
            <label htmlFor="query">Query</label>
            <textarea
              id="query"
              name="query"
              rows="4"
              value={formValues.query}
              onChange={handleChange}
              required
            ></textarea>
            {formErrors.query && (
              <span className="error">{formErrors.query}</span>
            )}
          </div>
          <button
            className="send-button"
            type="submit"
            disabled={isSubmitting}
          >
            {isSubmitting ? "Sending..." : "Send"}
          </button>
        </form>
      </div>
    </section>
  );
};

export default Connect;
