import React from 'react';
import { Link } from 'react-router-dom';
import '../Thankyou/Thankyou.css';

const ThankYou = () => {
  return (
    <div className="thankyou-container">
      <div className="thankyou-content">
        <h1 className="thankyou-heading">Thank You for Your Inquiry!</h1>
        <p className="thankyou-message">
          We appreciate your interest in our properties. One of our agents will
          be in touch with you shortly. Meanwhile, feel free to explore more or
          reach out if you have any questions.
        </p>
        <div className="cta-buttons">
          <Link to="/" className="cta-button home-button">
            Back to Home
          </Link>
         
          <Link to="/contactus" className="cta-button contact-button">
            Contact Us
          </Link>
        </div>
      </div>
     
    </div>
  );
};

export default ThankYou;
