import React from 'react';
import Slider from 'react-slick';
import { 
  FaDoorClosed, 

  FaChargingStation, 
 
  FaCar, 
  
} from 'react-icons/fa';
import { MdOutlineBalcony } from "react-icons/md";
import { PiSecurityCameraFill } from "react-icons/pi";
import { TbClock24 } from "react-icons/tb";
import { GiElevator } from "react-icons/gi";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../Amenities/Amenities.css';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

const NextArrow = ({ onClick }) => {
  return (
    <div className="slick-arrow slick-next" onClick={onClick}>
      <FaArrowRight />
    </div>
  );
};

const PrevArrow = ({ onClick }) => {
  return (
    <div className="slick-arrow slick-prev" onClick={onClick}>
      <FaArrowLeft />
    </div>
  );
};

const Amenities = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const amenities = [
    { icon: <TbClock24 />, name: 'Magnificent Drop-off With 24x7 Security System' },
    { icon: <FaDoorClosed />, name: 'Plot Open from all 4 sides' },
    { icon: <GiElevator  />, name: 'Dedicated High-Speed Elevators' },
    { icon: <MdOutlineBalcony />, name: 'Unnterrupted views from the bolcony' },
    { icon: <FaChargingStation />, name: 'EV Charging Bays' },
    { icon: <PiSecurityCameraFill />, name: 'CCTV Surveillance' },
    { icon: <FaCar />, name: 'Car Parking Available in the Basement' },
  ];

  return (
    <div className="amenities-section">
      <h2 className="amenities-title">Amenities</h2>
      <Slider {...settings} className="amenities-slider">
        {amenities.map((amenity, index) => (
          <div key={index} className="amenity-card">
            <div className="amenity-icon">{amenity.icon}</div>
            <p className="amenity-name">{amenity.name}</p>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Amenities;
