import React, { useState } from "react";
import "../Header/Header.css";
import Modal from "../Modal/Modal";
import { useNavigate } from "react-router-dom";
import { TiThMenu } from "react-icons/ti";
import { Link } from "react-router-dom";
import emailjs from "emailjs-com";
import Swal from "sweetalert2";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const [modalTitle, setModalTitle] = useState("Get More Details");
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    mobile: "",
    query: "",
    option: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [submissionError, setSubmissionError] = useState("");

  const validate = (name, value) => {
    let errors = { ...formErrors };

    switch (name) {
      case "name":
        errors.name = /^[A-Za-z\s]+$/.test(value) ? "" : "Name should contain only alphabets.";
        break;
      case "email":
        errors.email = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ? "" : "Invalid email address.";
        break;
      case "mobile":
        errors.mobile = /^\d{10}$/.test(value) ? "" : "Mobile number should be 10 digits.";
        break;
      case "option":
        errors.option = value ? "" : "Please select an option.";
        break;
      case "query":
        errors.query = value.length >= 3 ? "" : "Query should be at least 15 characters long.";
        break;
      default:
        break;
    }

    setFormErrors(errors);
  };

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLinkClick = () => {
    setIsMenuOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    validate(name, value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate all fields before submitting
    Object.keys(formValues).forEach((name) => validate(name, formValues[name]));

    if (Object.values(formErrors).some((error) => error !== "")) {
      setSubmissionError("Please fix the errors in the form.");
      return;
    }

    setSubmitting(true);

    console.log("Form Values:", formValues); // Debug: Check the data being sent

    emailjs
      .send("service_gwx9h8c", "template_8fchos7", formValues, "5vvqi6coftIzkClRe")
      .then(
        (response) => {
          Swal.fire("Thank You!", "Email sent successfully!", "success");
          setSubmitting(false);
          setShowModal(false);
          navigate("/thankyou");
        },
        (error) => {
          console.log("FAILED...", error);
          setSubmitting(false);
          setSubmissionError("Failed to send email. Please try again later.");
          Swal.fire("Error", "Failed to send email. Please try again later.", "error");
        }
      );
  };

  const calladmin = () => {
    window.location.href = "tel:+917303734500";
  };

  const showModalHandler = (title) => {
    setModalTitle(title);
    setShowModal(true);
  };

  const hideModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <nav>
        <div className="wrapper-nav">
          <div className="logo">
            <Link to="/">
              <img src="logo.png" className="logo-head" alt="logo" />
            </Link>
          </div>
          <ul className={`nav-links ${isMenuOpen ? "active" : ""}`}>
            <li>
              <Link to="/" onClick={handleLinkClick}>
                Home
              </Link>
            </li>
            <li>
              <Link to="/about" onClick={handleLinkClick}>
                About
              </Link>
            </li>
            <li>
              <Link to="/layout" onClick={handleLinkClick}>
                Layouts
              </Link>
            </li>
            <li>
              <Link to="#" onClick={() => showModalHandler("Get Our Brochure")}>
                Brochure
              </Link>
            </li>
            <li>
              <Link to="/pricelist" onClick={handleLinkClick}>
                Price List
              </Link>
            </li>
            <li>
              <Link to="/contactus" onClick={handleLinkClick}>
                Contact
              </Link>
            </li>
            <li>
              <button className="butt-nav" onClick={calladmin}>
                +91 7303734500
              </button>
            </li>
          </ul>
          <div className="menu-btn" onClick={handleMenuToggle}>
            <TiThMenu className="menu-ic" />
          </div>
        </div>
      </nav>

      <Modal show={showModal} handleClose={hideModal}>
        <h2 className="modal-heading">{modalTitle}</h2>
        <form className="modal-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formValues.name}
              onChange={handleChange}
              className={`input ${formErrors.name ? "invalid" : ""}`}
              required
            />
            {formErrors.name && <span className="error">{formErrors.name}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formValues.email}
              onChange={handleChange}
              className={`input ${formErrors.email ? "invalid" : ""}`}
              required
            />
            {formErrors.email && <span className="error">{formErrors.email}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="mobile">Mobile:</label>
            <input
              type="tel"
              id="mobile"
              name="mobile"
              value={formValues.mobile}
              onChange={handleChange}
              className={`input ${formErrors.mobile ? "invalid" : ""}`}
              required
            />
            {formErrors.mobile && <span className="error">{formErrors.mobile}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="option">Select Flat Option:</label>
            <select
              id="option"
              name="option"
              value={formValues.option}
              onChange={handleChange}
              className={`input ${formErrors.option ? "invalid" : ""}`}
              required
            >
              <option value="">-Select-</option>
              <option value="4.5-XL BHK(4190 SQ. FT.)">4.5-XL BHK(4190 SQ. FT.)</option>
              <option value="4.5 BHK(3200 SQ. FT.)">4.5 BHK(3200 SQ. FT.)</option>
              <option value="3.5 BHK(2290 SQ. FT.)">3.5 BHK(2290 SQ. FT.)</option>
            </select>
            {formErrors.option && <span className="error">{formErrors.option}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="query">Your Query:</label>
            <textarea
              id="query"
              name="query"
              value={formValues.query}
              onChange={handleChange}
              className={`input ${formErrors.query ? "invalid" : ""}`}
              rows="4"
              required
            />
            {formErrors.query && <span className="error">{formErrors.query}</span>}
          </div>
          <div className="butt-f">
            <button className="button-3" type="submit" disabled={submitting}>
              {submitting ? "Submitting..." : "SUBMIT"}
            </button>
          </div>
          {submissionError && <p className="submission-error">{submissionError}</p>}
        </form>
      </Modal>
    </>
  );
};

export default Header;
