import React from 'react';
import { FaMapMarkerAlt, FaBuilding, FaRupeeSign, FaInfoCircle } from 'react-icons/fa';
import '../Details/Details.css'
import { FaKey } from "react-icons/fa";
const Details = () => {
  return (
    <div className="details-section">
      <div className="detail-item">
      <div className='idd'>
        <FaMapMarkerAlt className="detail-icon" />
        </div>
        <div>
          <h4>Project Location</h4>
         
          <p>Sector 12 Greater Noida West</p>
        </div>
      </div>
      <div className="detail-item">
      <div className='idd'>
        <FaBuilding className="detail-icon" />
        </div>
        <div>
          <h4>Unit Variants</h4>
        
          <p>3.5,4.5 & 4.5 XL BHK Luxury Flat</p>
        </div>
      </div>
      <div className="detail-item">
      <div className='idd'>
        <FaRupeeSign className="detail-icon" />
        </div>
        <div>
          <h4>Starting Price</h4>
        
          <p>Enquire Now</p>
        </div>
      </div>
      <div className="detail-item">
      <div className='idd'>
        <FaKey  className="detail-icon" />
        </div>
        <div>
        
          <h4>RERA Number</h4>
         
          <p>UPRERAPRJ677887</p>
        </div>
     
    </div>
    </div>
  );
};

export default Details;
