import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import Modal from '../Modal/Modal';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import '../Price/Price.css';

const Price = () => {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({ name: '', email: '', mobile: '', query: '',option:'' });
  const [formErrors, setFormErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [submissionError, setSubmissionError] = useState('');

  const handleShowModal = () => setShowModal(true);
  const hideModal = () => setShowModal(false);

  const validate = (name, value) => {
    let errors = { ...formErrors };

    switch (name) {
      case 'name':
        errors.name = /^[A-Za-z\s]+$/.test(value) ? '' : 'Name should contain only alphabets.';
        break;
      case 'email':
        errors.email = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ? '' : 'Invalid email address.';
        break;
      case 'mobile':
        errors.mobile = /^\d{10}$/.test(value) ? '' : 'Mobile number should be 10 digits.';
        break;
        case 'option':
          errors.option = value ? '' : 'Please select an option.';
          break;
          case 'query':
      if (!value.trim()) {
        errors.query = 'Query is required';
      } else if (value.length < 3) {
        errors.query = 'Query should be at least 15 characters long';
      } else {
        errors.query = '';
      }
      break;
      
      default:
        break;
    }

    setFormErrors(errors);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    validate(name, value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    
    Object.keys(formValues).forEach((name) => validate(name, formValues[name]));

    if (Object.values(formErrors).some((error) => error !== '')) {
      setSubmissionError('Please fix the errors in the form.');
      return;
    }

    setSubmitting(true);

   
    emailjs.send('service_gwx9h8c', 'template_8fchos7', formValues, '5vvqi6coftIzkClRe')
    .then((response) => {
      Swal.fire('Thank You!', 'Email sent successfully!', 'success');
      setSubmitting(false);
      setShowModal(false);
      navigate('/thankyou');
      setFormValues({ name: '', email: '', mobile: '', query: '' });
      setFormErrors({});
      setSubmissionError('');
    })
    .catch((error) => {
      
      setSubmitting(false);
      setSubmissionError('Failed to send email. Please try again.');
      Swal.fire('Error', 'Failed to send email. Please try again later.', 'error');
    });
  };

  return (
    <>
    <div className="price-section">
      <div className="left-p">
        <h2 className="price-heading2">Price for Ace Hanei Sector 12</h2>
        <p className="price-subheading2">Perfect home for your needs</p>
      </div>
      <div className="right-p">
        <h3 className="price-list-heading">Price List(<strong>ACE Hanei</strong> Sector 12)</h3>
        <table className="price-table">
          <thead>
            <tr>
              <th>Types of Unit</th>
              <th> Area</th>
              <th>Price List</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>3.5 BHK (TYPE-1)</td>
              <td>Area (2290) SQ.FT. (Mivan Constructions)</td>
              <td>Cost - <span className="enquire-link" onClick={handleShowModal}>Price on Demand</span></td>
            </tr>
            <tr>
              <td>4.5 BHK (TYPE-2)</td>
              <td>Area (3200) SQ.FT. (Mivan Constructions)</td>
              <td>Cost - <span className="enquire-link" onClick={handleShowModal}>Price on Demand</span></td>
            </tr>
            <tr>
              <td>4.5-XL BHK (TYPE-3)</td>
              <td>Area (4190) SQ.FT. (Mivan Constructions)</td>
              <td>Cost - <span className="enquire-link" onClick={handleShowModal}>Price on Demand</span></td>
            </tr>
            
            {/* Add more rows as needed */}
          </tbody>
        </table>
      </div>


      

      <Modal show={showModal} handleClose={hideModal}>
        <h2 className="modal-heading">Get More Details</h2>
        <form className="modal-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formValues.name}
              onChange={handleChange}
              className={`input ${formErrors.name ? 'invalid' : ''}`}
              required
            />
            {formErrors.name && <span className="error">{formErrors.name}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formValues.email}
              onChange={handleChange}
              className={`input ${formErrors.email ? 'invalid' : ''}`}
              required
            />
            {formErrors.email && <span className="error">{formErrors.email}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="mobile">Mobile:</label>
            <input
              type="tel"
              id="mobile"
              name="mobile"
              value={formValues.mobile}
              onChange={handleChange}
              className={`input ${formErrors.mobile ? 'invalid' : ''}`}
              required
            />
            {formErrors.mobile && <span className="error">{formErrors.mobile}</span>}
          </div>
          <div className="form-group">
                        <label htmlFor="option">Select Flat Option:</label>
                        <select
                            id="option"
                            name="option"
                            value={formValues.option}
                            required
                            onChange={handleChange}
                            className={`input ${formErrors.option ? 'invalid' : ''}`}
                        >
                            <option value="">-Select-</option>
                            <option value="4.5-XL BHK(4190 SQ. FT.)">4.5-XL BHK(4190 SQ. FT.)</option>
                            <option value="4.5 BHK(3200 SQ. FT.)">4.5 BHK(3200 SQ. FT.)</option>
                            <option value="3.5 BHK(2290 SQ. FT.)">3.5 BHK(2290 SQ. FT.)</option>
                        </select>
                        {formErrors.option && <span className="error">{formErrors.option}</span>}
                    </div>
          <div className="form-group">
            <label htmlFor="query">Your Query:</label>
            <textarea
              id="query"
              name="query"
              required
              value={formValues.query}
              onChange={handleChange}
              className={`input ${formErrors.query ? 'invalid' : ''}`}
              rows="4"
              
            />
            {formErrors.query && <span className="error">{formErrors.query}</span>}
          </div>
          <div className="butt-f">
            <button className="button-3" type="submit" disabled={submitting}>
              {submitting ? 'Submitting...' : 'SUBMIT'}
            </button>
          </div>
          {submissionError && <p className="submission-error">{submissionError}</p>}
        </form>
      </Modal>
    </div>
    
    </>
  );
};

export default Price;
