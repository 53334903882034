import React, { useState, useEffect } from 'react';
import Slider from '../Slider/Slider';
import Text from '../Text/Text';
import { useNavigate } from 'react-router-dom';
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2';
import Card from '../Card/Card';
import Modal from '../Modal/Modal';
import Details from '../Details/Details';
import Gallery from '../Gallery/Gallery';
import Price from '../Price/Price';
import Amenities from '../Amenities/Amenities';
import Location from '../Location/Location';

const Home = () => {
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();
    const [formValues, setFormValues] = useState({
        name: '',
        email: '',
        mobile: '',
        query: '',
        option: '',
    });
    const [formErrors, setFormErrors] = useState({});
    const [submitting, setSubmitting] = useState(false);
    const [submissionError, setSubmissionError] = useState('');

    useEffect(() => {
        setShowModal(true);
    }, []);

    const hideModal = () => setShowModal(false);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
        validateField(name, value);
    };

    const validateField = (fieldName, value) => {
        let errorMessage = '';

        switch (fieldName) {
            case 'name':
                if (!value.trim()) errorMessage = 'Name is required';
                else if (!/^[A-Za-z\s]+$/.test(value)) errorMessage = 'Name should contain only alphabets';
                break;
            case 'email':
                if (!value.trim()) errorMessage = 'Email is required';
                else if (!/\S+@\S+\.\S+/.test(value)) errorMessage = 'Email is invalid';
                break;
            case 'mobile':
                if (!value.trim()) errorMessage = 'Mobile number is required';
                else if (!/^\d{10}$/.test(value)) errorMessage = 'Mobile number should be 10 digits';
                break;
            case 'option':
                if (!value) errorMessage = 'Please select an option';
                break;
            case 'query':
                if (!value.trim()) errorMessage = 'Query is required';
                else if (value.length < 3) errorMessage = 'Query should be at least 15 characters long';
                break;
            default:
                break;
        }

        setFormErrors((prevErrors) => ({ ...prevErrors, [fieldName]: errorMessage }));
    };

    const validateForm = () => {
        let isValid = true;

        Object.entries(formValues).forEach(([fieldName, value]) => {
            validateField(fieldName, value);
            if (!value || formErrors[fieldName]) isValid = false;
        });

        return isValid;
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if (!validateForm()) return;

        setSubmitting(true);

        emailjs
            .send('service_gwx9h8c', 'template_8fchos7', formValues, '5vvqi6coftIzkClRe')
            .then(() => {
                setSubmitting(false);
                Swal.fire('Thank You!', 'Email sent successfully!', 'success');
                setFormValues({
                    name: '',
                    email: '',
                    mobile: '',
                    query: '',
                    option: '',
                });
                setFormErrors({});
                hideModal();
                navigate('/thankyou');
            })
            .catch(() => {
                setSubmitting(false);
                setSubmissionError('Failed to send email. Please try again later.');
                Swal.fire('Error', 'Failed to send email. Please try again later.', 'error');
            });
    };

    return (
        <>
            <Modal show={showModal} handleClose={hideModal}>
                <h2 className="modal-heading">Get More Details</h2>
                <form className="modal-form" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="name">Name:</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            
                            value={formValues.name}
                            onChange={handleChange}
                            className={`input ${formErrors.name ? 'invalid' : ''}`}
                        />
                        {formErrors.name && <span className="error">{formErrors.name}</span>}
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Email:</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                           
                            value={formValues.email}
                            onChange={handleChange}
                            className={`input ${formErrors.email ? 'invalid' : ''}`}
                        />
                        {formErrors.email && <span className="error">{formErrors.email}</span>}
                    </div>
                    <div className="form-group">
                        <label htmlFor="mobile">Mobile:</label>
                        <input
                            type="tel"
                            id="mobile"
                            name="mobile"
                           required
                            value={formValues.mobile}
                            onChange={handleChange}
                            className={`input ${formErrors.mobile ? 'invalid' : ''}`}
                        />
                        {formErrors.mobile && <span className="error">{formErrors.mobile}</span>}
                    </div>
                    <div className="form-group">
                        <label htmlFor="option">Select Flat Option:</label>
                        <select
                            id="option"
                            name="option"
                            required
                            value={formValues.option}
                            onChange={handleChange}
                            className={`input ${formErrors.option ? 'invalid' : ''}`}
                        >
                            <option value="">-Select-</option>
                            <option value="4.5-XL BHK(4190 SQ. FT.)">4.5-XL BHK(4190 SQ. FT.)</option>
                            <option value="4.5 BHK(3200 SQ. FT.)">4.5 BHK(3200 SQ. FT.)</option>
                            <option value="3.5 BHK(2290 SQ. FT.)">3.5 BHK(2290 SQ. FT.)</option>
                        </select>
                        {formErrors.option && <span className="error">{formErrors.option}</span>}
                    </div>
                    <div className="form-group">
                        <label htmlFor="query">Your Query:</label>
                        <textarea
                            id="query"
                            name="query"
                           required
                            value={formValues.query}
                            onChange={handleChange}
                            className={`input ${formErrors.query ? 'invalid' : ''}`}
                            rows="4"
                        />
                        {formErrors.query && <span className="error">{formErrors.query}</span>}
                    </div>
                    <div className="butt-f">
                        <button className="button-3" type="submit" disabled={submitting}>
                            {submitting ? 'Submitting...' : 'SUBMIT'}
                        </button>
                    </div>
                    {submissionError && <p className="submission-error">{submissionError}</p>}
                </form>
            </Modal>
            <Slider />
            <Text />
            <Card />
            <Details />
            <Gallery />
            <Price />
            <Amenities />
            <Location />
        </>
    );
};

export default Home;
