import React from 'react'
import '../Layout/Layout.css'
import Card from '../Card/Card'
import Plan from '../Plan/Plan'
const Layout = () => {
  return (
    <>
    <div className='gappp'>
<Card/>
</div>

    </>
  )
}

export default Layout