import React,{useEffect} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import { useLocation } from 'react-router-dom';
import Header from './Components/Header/Header';
import Home from './Components/Home/Home';
import Footer from './Components/Footer/Footer';
import About from './Components/About/About';
import Layout from './Components/Layout/Layout';
import Pricelist from './Components/Pricelist/Pricelist';
import Contactus from './Components/Contactus/Contactus';
import Chatbox from './Components/Chatbox/Chatbox';
import Privacypolicy from './Components/Privacypolicy/Privacypolicy';

import Footer2 from './Components/Footer/Footer2';
import ThankYou from './Components/Thankyou/Thankyou';
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant'  
    });
  }, [pathname]);

  return null;
}
function App() {
  return (
    <Router>
      <>
        <Header />
        <ScrollToTop/>
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/about" element={<About/>} />
          <Route path="/thankyou" element={<ThankYou/>} />
          <Route path="/layout" element={<Layout/>} />
          <Route path="/pricelist" element={<Pricelist/>} />
          <Route path="/contactus" element={<Contactus/>} />
          <Route path="/privacy" element={<Privacypolicy/>} />
         
        </Routes>
        <Chatbox/>
        <Footer2/>
      </>
    </Router>
  );
}

export default App;
